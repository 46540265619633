import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {loginUser} from '../../actions/login';
import {connect} from 'react-redux';
import baseurl from '../../util/baseurl';

const brandSuccess = getStyle('--success')
const brandDanger = getStyle('--danger')

// Card Chart 4
const cardChartData4 = {
  labels: ['week-1', 'week-2', 'week-3', 'week-4'],
  datasets: [
    {
      label: 'Fuel Usage',
      backgroundColor: brandSuccess,
      borderColor: 'transparent',
      data: [78, 81, 80, 69],
    },
  ],
};

const cardChartOpts4 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        barPercentage: 0.6,
      }],
    yAxes: [
      {
        display: true,
        scaleLabel: {
        display: true,
        labelString: 'Fuel in Lit'
      }
      }],
  },
};
const cardChartData5 = {
  labels: ['week-1', 'week-2', 'week-3', 'week-4'],
  datasets: [
    {
      label: 'Breakdowns',
      backgroundColor: brandDanger,
      borderColor: 'transparent',
      data: [12, 14, 9, 18],
    },
  ],
};

const cardChartOpts5 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        barPercentage: 0.6,
      }],
    yAxes: [
      {
        display: true,
        scaleLabel: {
        display: true,
        labelString: 'No of BreakDowns'
      }
      }],
  },
};

// Social Box Chart


class ManagerDashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      driverCount:0,
      vehicleCount:0,
      tripCount:0,
      dateButtonName:'Filter By Date'
    }
    this.handleEvent = this.handleEvent.bind(this)
  }

  handlePostApiCall(url,attribute,data){
    axios.post(url,data)
    .then(response => {
      this.setState({
        [attribute]:response.data.data.length
      })
    })
    .catch(err => {
      throw err
    })
  }
  componentWillMount(){
    var zone = this.props.user.zone
    var startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0,10);
    var endDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0,10);
    var data ={
      zone: zone,
      startDate: startDate,
      endDate: endDate
    }
    this.setState({
      dateButtonName: startDate+' - '+endDate
    })
    this.handlePostApiCall(baseurl+'/getZonalDrivers','driverCount',data)
    this.handlePostApiCall(baseurl+'/getVehiclesByZone','vehicleCount',data)
    this.handlePostApiCall(baseurl+'/getZoneTrips','tripCount',data)
  }
  handleEvent(event, picker){
    var zone = this.props.user.zone
    var startDate = picker.startDate.format()
    var endDate = picker.endDate.format()
    var data ={
      zone: zone,
      startDate: startDate,
      endDate: endDate
    }
    this.setState({
      dateButtonName: startDate.slice(0,10)+' - '+endDate.slice(0,10)
    })
    this.handlePostApiCall(baseurl+'/getZonalDrivers','driverCount',data)
    this.handlePostApiCall(baseurl+'/getVehiclesByZone','vehicleCount',data)
    this.handlePostApiCall(baseurl+'/getZoneTrips','tripCount',data)
  }
  render() {
    var zone = ''
    if(this.props.user.zone == 'zone-1')
      zone = 'Zone 1'
    else if(this.props.user.zone == 'zone-2')
      zone = 'Zone 2'
    else if(this.props.user.zone == 'zone-3')
      zone = 'Zone 3'
    else
      zone = 'Zone 4'
    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="2" md="2">
            <DateRangePicker onApply={this.handleEvent}>
                <Button block color="info">{this.state.dateButtonName}</Button>
              </DateRangePicker>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="2" md="2">
            <h3 className="pb-4 text-muted">{zone}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-info">
              <CardBody >
                <Row>
                  <Col md="8">
                    <h4>Drivers</h4>
                  </Col>
                  <Col md="4">
                    <h4>{this.state.driverCount}</h4>
                  </Col> 
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-primary">
              <CardBody >
                <Row>
                  <Col md="8">
                    <h4>Vehicles</h4>
                  </Col>
                  <Col md="4">
                    <h4>{this.state.vehicleCount}</h4>
                  </Col> 
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-warning">
              <CardBody>
                <Row>
                  <Col md="8">
                    <h4>Trips</h4>
                  </Col>
                  <Col md="4">
                    <h4>{this.state.tripCount}</h4>
                  </Col> 
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p>Fuel Efficiency</p>
          </Col>
          <Col md="6">
            <p>Breakdowns</p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Bar data={cardChartData4} options={cardChartOpts4}/>
                </div> 
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Bar data={cardChartData5} options={cardChartOpts5}/>
                </div> 
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({login}) => ({
  user: login.data
})
export default connect(mapStateToProps,{loginUser})(ManagerDashboard);

