export const START_FEED = 'START_FEED';
export const END_FEED = 'END_FEED';

/**
 * Imperatively start global progress.
 *
 * @return {Object} Action for starting global progress.
 */
export const realFeedOn = () => ({
  type: START_FEED,
});

/**
 * Imperatively stop global progress.
 *
 * @return {Object} Action for stopping global progress.
 */
export const realFeedOff = () => ({
  type: END_FEED,
});
