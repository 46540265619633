import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import Avatar from 'react-avatar';
import logo from '../../assets/img/brand/thingsphere-black.png'
import sygnet from '../../assets/img/brand/sygnet.svg'
import {logoutUser} from '../../actions/login';
import {connect} from 'react-redux';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props){
    super(props)
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(){
    //this.props.history.push('/login')
    this.props.logoutUser()
  }
  render() {
    if(this.props.user){
      var navbar = ''
      if(this.props.user.role == 'businessUser')
        navbar = <NavLink href="#/dashboard">Logged In as Business User</NavLink>
      else if(this.props.user.role == 'operationManager')
        navbar = <NavLink href="#/dashboard">Logged In as Operations Manager</NavLink>
      else if (this.props.user.role == 'admin')
        navbar = <NavLink href="#/deviceInfo">Logged In as Admin</NavLink>
    }
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 150, height: 25, alt: 'ThingSphere Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="#/deviceInfo">Machine Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">
            {navbar}
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <div className="device">
            {(this.props.realFeed)?<span className="device-status badge-success"></span>:<span className="device-status badge-success"></span>}
          </div>
          {(this.props.realFeed)?
            <NavItem>
              <NavLink href="#">&nbsp;Real Time Feed Online&nbsp;&nbsp;&nbsp;</NavLink>
            </NavItem>:
            <NavItem>
              <NavLink href="#">&nbsp;Real Time Feed Online&nbsp;&nbsp;&nbsp;</NavLink>
            </NavItem>
          }
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              {(this.props.user)?
                <div className="username-align">
                  {this.props.user.username}&nbsp;<Avatar name={this.props.user.username} size="40" round={true}/> &nbsp;
                </div>:''}
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem href="#/login" onClick={this.handleClick}><i className="fa fa-lock"></i>Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = ({login,realFeed}) => ({
  user: login.data,
  realFeed: realFeed.realFeed
})
export default connect(mapStateToProps,{logoutUser})(DefaultHeader);
