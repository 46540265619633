/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'react-elemental';

/**
 * Orion logo and wordmark element.
 */
const Logo = ({ color, style, ...props }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 692.57 172.419"
    enableBackground="new 0 0 692.57 172.419"
    xmlSpace="preserve"
    style={{ fill: color, stroke: color, ...style }}
    {...props}
  >
    <line fill="none" strokeWidth="4" strokeMiterlimit="10" x1="8.928" y1="42.465" x2="70.714" y2="8.764" />
    <line fill="none" strokeWidth="4" strokeMiterlimit="10" x1="70.714" y1="8.764" x2="116.773" y2="34.602" />
    <line fill="none" strokeWidth="4" strokeMiterlimit="10" x1="116.773" y1="34.602" x2="108.348" y2="131.212" />
    <line fill="none" strokeWidth="4" strokeMiterlimit="10" x1="108.348" y1="131.212" x2="70.714" y2="163.79" />
    <line fill="none" strokeWidth="4" strokeMiterlimit="10" x1="70.714" y1="163.79" x2="8.928" y2="42.465" />
    <g>
      <path
        d="M248.799,119.337c-5.473,0-10.459-1.096-14.958-3.293c-4.5-2.195-8.37-5.111-11.61-8.748
        c-3.24-3.635-5.743-7.793-7.506-12.475c-1.765-4.679-2.646-9.467-2.646-14.364c0-5.112,0.935-10.007,2.808-14.688
        c1.872-4.68,4.445-8.819,7.722-12.42c3.275-3.599,7.164-6.461,11.664-8.586c4.499-2.123,9.413-3.186,14.742-3.186
        c5.471,0,10.458,1.134,14.958,3.402c4.499,2.268,8.352,5.238,11.556,8.91c3.203,3.672,5.687,7.83,7.452,12.474
        c1.763,4.644,2.646,9.379,2.646,14.202c0,5.113-0.918,10.008-2.754,14.688c-1.836,4.682-4.411,8.803-7.722,12.366
        c-3.313,3.564-7.219,6.409-11.718,8.532C258.933,118.276,254.054,119.337,248.799,119.337z M224.391,80.458
        c0,3.601,0.576,7.092,1.728,10.476c1.151,3.385,2.79,6.372,4.914,8.964c2.123,2.592,4.698,4.662,7.722,6.21
        c3.024,1.549,6.407,2.321,10.152,2.321c3.888,0,7.326-0.81,10.314-2.43c2.987-1.619,5.525-3.762,7.614-6.426
        c2.087-2.662,3.672-5.651,4.752-8.964c1.08-3.311,1.62-6.696,1.62-10.152c0-3.672-0.577-7.182-1.728-10.53
        c-1.153-3.348-2.792-6.299-4.914-8.856c-2.125-2.555-4.681-4.607-7.668-6.156c-2.989-1.547-6.318-2.322-9.99-2.322
        c-3.888,0-7.344,0.81-10.368,2.43c-3.024,1.62-5.581,3.726-7.668,6.318c-2.089,2.592-3.69,5.562-4.806,8.91
        C224.948,73.599,224.391,77.001,224.391,80.458z"
      />
      <path
        d="M334.875,118.798V42.117h33.372c3.456,0,6.642,0.721,9.558,2.16c2.916,1.441,5.417,3.331,7.506,5.67
        c2.087,2.341,3.726,5.005,4.914,7.992c1.188,2.988,1.782,6.031,1.782,9.126c0,2.592-0.361,5.076-1.08,7.452
        c-0.72,2.376-1.728,4.573-3.024,6.588c-1.296,2.017-2.88,3.763-4.752,5.238c-1.873,1.477-3.925,2.575-6.156,3.294l18.036,29.161
        h-13.716l-16.632-26.676h-17.712v26.676H334.875z M346.971,81.429h21.168c1.728,0,3.294-0.378,4.698-1.134s2.609-1.799,3.618-3.132
        c1.007-1.332,1.799-2.862,2.376-4.59c0.575-1.728,0.864-3.564,0.864-5.508s-0.342-3.78-1.026-5.508
        c-0.685-1.728-1.584-3.24-2.7-4.536c-1.117-1.296-2.413-2.322-3.888-3.078c-1.477-0.756-3.007-1.134-4.59-1.134h-20.52V81.429z"
      />
      <path d="M442.983,118.798V42.117h12.096v76.681H442.983z" />
      <path
        d="M540.937,119.337c-5.473,0-10.459-1.096-14.958-3.293c-4.501-2.195-8.37-5.111-11.61-8.748
        c-3.239-3.635-5.742-7.793-7.506-12.475c-1.765-4.679-2.646-9.467-2.646-14.364c0-5.112,0.935-10.007,2.808-14.688
        c1.871-4.68,4.445-8.819,7.722-12.42c3.275-3.599,7.164-6.461,11.664-8.586c4.499-2.123,9.413-3.186,14.742-3.186
        c5.471,0,10.458,1.134,14.958,3.402c4.499,2.268,8.352,5.238,11.557,8.91c3.202,3.672,5.687,7.83,7.451,12.474
        c1.764,4.644,2.646,9.379,2.646,14.202c0,5.113-0.918,10.008-2.754,14.688c-1.836,4.682-4.411,8.803-7.723,12.366
        c-3.313,3.564-7.219,6.409-11.718,8.532C551.071,118.276,546.192,119.337,540.937,119.337z M516.529,80.458
        c0,3.601,0.575,7.092,1.729,10.476c1.15,3.385,2.789,6.372,4.914,8.964c2.122,2.592,4.697,4.662,7.722,6.21
        c3.024,1.549,6.407,2.321,10.152,2.321c3.888,0,7.325-0.81,10.313-2.43c2.987-1.619,5.525-3.762,7.614-6.426
        c2.087-2.662,3.672-5.651,4.752-8.964c1.08-3.311,1.62-6.696,1.62-10.152c0-3.672-0.577-7.182-1.729-10.53
        c-1.152-3.348-2.791-6.299-4.914-8.856c-2.124-2.555-4.681-4.607-7.668-6.156c-2.988-1.547-6.317-2.322-9.989-2.322
        c-3.889,0-7.345,0.81-10.368,2.43c-3.024,1.62-5.581,3.726-7.668,6.318c-2.09,2.592-3.69,5.562-4.807,8.91
        C517.085,73.599,516.529,77.001,516.529,80.458z"
      />
      <path d="M639.11,64.797v54h-12.097V42.117h9.504l43.849,55.296V42.226h12.204v76.572h-10.044L639.11,64.797z" />
    </g>
    <polygon points="8.928,42.465 8.928,42.465 8.928,42.465 8.928,42.465 8.928,42.465 8.928,42.465 " />
    <circle cx="9" cy="42.724" r="9" />
    <circle cx="71.123" cy="9" r="9" />
    <circle cx="116.383" cy="34.736" r="9" />
    <circle cx="108.396" cy="131.471" r="9" />
    <circle cx="71.123" cy="163.42" r="9" />
  </svg>
);

Logo.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

Logo.defaultProps = {
  color: colors.gray75,
  style: {},
};

export default Logo;
