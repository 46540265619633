import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import 'mapbox-gl/dist/mapbox-gl.css';
import { Provider } from 'react-redux';
import {store,persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react'


ReactDOM.render(
    <Provider store={store}>
	    <PersistGate loading={null} persistor={persistor}>
	        <App />
	    </PersistGate>
    </Provider>,
    document.getElementById('root')
);
