export default {
  items: [
    // {
    //   name: 'Overview',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer'
    // },
    // {
    //   title: true,
    //   name: 'Region Summary',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Add Driver',
    //   url: '/addDriver',
    //   icon: 'icon-drop',
    // },
    // {
    //   name: 'Add Vehicle',
    //   url: '/addVehicle',
    //   icon: 'icon-pencil',
    // },
    // {
    //   name: 'Add Device',
    //   url: '/addDevice',
    //   icon: 'icon-pencil',
    // },
    {
      name: 'Dashboard',
      url: '/deviceInfo',
      icon: 'icon-speedometer'
    },
    {
      name: 'Analytics',
      url: '/deviceStatus',
      icon: 'icon-pie-chart'
    },
  ]  
};
