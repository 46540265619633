import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import baseurl from '../../util/baseurl';
import ActiveDevices from '../BusinessUser/devices'

const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

var zoneCount = (attribute) => {
  var attributePerZone = attribute.map((att,index)=>{
    return att.count
  })
  return attributePerZone
}

var zoneStatistics = (attribute) =>{
  var totalFuel = attribute.reduce((a, b) => a + b, 0);
  var fuelEfficiency = attribute.map((fuel, index) => {
    var fuelPercent = Math.round(((fuel/totalFuel)*100)*100)/100//Round to two decimal places
    return fuelPercent
  })
  return fuelEfficiency
}


const cardChartOpts4 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        barPercentage: 0.6,
      }],
    yAxes: [
      {
        display: true,
        ticks:{
          display: true,
          min: 0
        },
        scaleLabel: {
        display: true,
        labelString: 'Fuel in Lit'
      }
      }],
  },
};


const cardChartOpts5 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        barPercentage: 0.6,
      }],
    yAxes: [
      {
        display: true,
        ticks:{
          display: true,
          min: 0
        },
        scaleLabel: {
        display: true,
        labelString: 'No of BreakDowns'
      }
      }],
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      driversCount:[{zone:'zone-1',count:0},{zone:'zone-2',count:0},{zone:'zone-3',count:0},{zone:'zone-4',count:0}],
      vehiclesCount:[{zone:'zone-1',count:0},{zone:'zone-2',count:0},{zone:'zone-3',count:0},{zone:'zone-4',count:0}],
      tripsCount:[{zone:'zone-1',count:0},{zone:'zone-2',count:0},{zone:'zone-3',count:0},{zone:'zone-4',count:0}],
      fuel:[{zone:'zone-1', count:0},{zone:'zone-2', count:0},{zone:'zone-3', count:0},{zone:'zone-4', count:0}],
      breakdown:[{zone:'zone-1', count:0},{zone:'zone-2', count:0},{zone:'zone-3', count:0},{zone:'zone-4', count:0}],
      message:'',
      dateButtonName:'Filter By Date'
    };
    this.handleEvent = this.handleEvent.bind(this);
  }

  handlePostApiCall(url,data,attributeCount){
    axios.post(url,data)
    .then(response => {
      if(response.data.data=='No Result'){
        this.setState({
          message:'No Data',
          [attributeCount]:[{zone:'zone-1',count:0},{zone:'zone-2',count:0},{zone:'zone-3',count:0},{zone:'zone-4',count:0}]
        })
      }else if(response.data.data.length<4){
      var length = response.data.data.length;
      var i=0;
      var stateCopy = Object.assign({}, this.state);
      stateCopy[attributeCount] = [{zone:'zone-1', count:0},{zone:'zone-2', count:0},{zone:'zone-3', count:0},{zone:'zone-4', count:0}]
      while(i<length){
        stateCopy[attributeCount].forEach((err, index) =>{
          if(response.data.data[i]._id === stateCopy[attributeCount][index].zone)
            stateCopy[attributeCount][index] = response.data.data[i];
        })
        i++;
      }
      this.setState(stateCopy);
      }else{
        this.setState({
          message:'',
          [attributeCount]:response.data.data
        })
      }
    }).catch(err => {
      throw err
    })
  }

  componentWillMount(){
    var startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0,10);
    var endDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0,10);
    var data = {
      startDate : startDate,
      endDate : endDate
    }
    var name = startDate+' - '+endDate
    this.setState({
      dateButtonName:name
    })
    this.handlePostApiCall(baseurl+'/getTripsCount',data,'tripsCount');
    this.handlePostApiCall(baseurl+'/getVehiclesCount',data,'vehiclesCount');
    this.handlePostApiCall(baseurl+'/getDriversCount',data,'driversCount');
  }

  componentDidMount(){
    var startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0,10);
    var endDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0,10);
    var data = {
      startDate : startDate,
      endDate : endDate
    }
    this.handlePostApiCall(baseurl+'/getMonthlyFuel',data, 'fuel');
    this.handlePostApiCall(baseurl+'/getMonthlyBreakdown',data, 'breakdown');
  }
  handleEvent(event,picker){
    var startDate = picker.startDate.format()
    var endDate = picker.endDate.format()
    var data = {
      startDate : startDate,
      endDate : endDate
    }
    var name = startDate.slice(0,10)+' - '+endDate.slice(0,10)
    this.setState({
      dateButtonName:name
    })
    this.handlePostApiCall(baseurl+'/getTripsCount',data,'tripsCount');
    this.handlePostApiCall(baseurl+'/getVehiclesCount',data,'vehiclesCount');
    this.handlePostApiCall(baseurl+'/getDriversCount',data,'driversCount');
    this.handlePostApiCall(baseurl+'/getMonthlyFuel',data, 'fuel');
    this.handlePostApiCall(baseurl+'/getMonthlyBreakdown',data, 'breakdown');
  }
  render() {
    var zones = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4'];
    var boxColors = ["text-white bg-success","text-white bg-primary","text-white bg-warning","text-white bg-danger"];
    var fuel = this.state.fuel;
    var breakdown = this.state.breakdown;
    var fuelCount = zoneCount(fuel);
    var breakdownCount = zoneCount(breakdown);
    var fuelEfficiency = zoneStatistics(fuelCount);
    var breakdowns = zoneStatistics(breakdownCount);
    var hoverBackgroundColor = [brandSuccess, brandPrimary, brandWarning,brandDanger];
    var backgroundColor = [brandSuccess, brandPrimary, brandWarning,brandDanger];

    const PieData1 = {
      labels: zones,
      datasets: [{
        data: fuelEfficiency,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor
      }]
    };
    const PieData2 = {
      labels: zones,
      datasets: [{
        data: breakdowns,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor
      }]
    }
    const cardChartData4 = {
      labels: zones,
      datasets: [
        {
          label: 'Fuel Usage',
          backgroundColor: [brandSuccess, brandPrimary, brandWarning, brandDanger],
          borderColor: 'transparent',
          data: fuelCount,
        },
      ],
    };
    const cardChartData5 = {
      labels: ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      datasets: [
        {
          label: 'Breakdowns',
          backgroundColor: [brandSuccess, brandPrimary, brandWarning, brandDanger],
          borderColor: 'transparent',
          data: breakdownCount,
        },
      ],
    };
    return (
      <div className="animated fadeIn">
         <Row>
          <Col lg="2" md="2">
            <DateRangePicker onApply={this.handleEvent}>
                <Button block color="info">{this.state.dateButtonName}</Button>
              </DateRangePicker>
          </Col>
        </Row>
        <br />
        <Row>
          {zones.map((zone, index) => {
            var zoneLink = ''
            if(zone=="Zone 1")
              zoneLink = '/#/byZones/'+'zone-1'
            else if(zone=="Zone 2")
              zoneLink = '/#/byZones/'+'zone-2'
            else if(zone=="Zone 3")
              zoneLink = '/#/byZones/'+'zone-3'
            else
              zoneLink = '/#/byZones/'+'zone-4'
            return(
              <Col xs="12" sm="6" lg="3" key={index}>
              <Card className={boxColors[index]}>
                <CardBody>
                  <h5><strong><a href={zoneLink} className = 'a-h5'>{zone}</a></strong></h5>
                  <Row>
                    <Col md="8">
                      <div>Drivers</div>
                      <div>Vehicles</div>
                      <div>Trips</div>
                    </Col>
                    <Col md="4">
                      <div>{this.state.driversCount[index].count}</div>
                      <div>{this.state.vehiclesCount[index].count}</div>
                      <div>{this.state.tripsCount[index].count}</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
              )
          })}
        </Row>
        <Row>
          <Col md="6">
            <p>Fuel Efficiency (%)</p>
          </Col>
          <Col md="6">
            <p>Breakdowns (%)</p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Pie data={PieData1}/>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Pie data={PieData2}/>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p>Fuel Usage</p>
          </Col>
          <Col md="6">
            <p>Breakdowns</p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Bar data={cardChartData4} options={cardChartOpts4}/>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="text-white">
              <CardBody>
                <div>
                  <Bar data={cardChartData5} options={cardChartOpts5}/>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ActiveDevices/>
      </div>
    );
  }
}

export default Dashboard
