import axios from 'axios';
import baseurl from '../util/baseurl';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
var headers = {
	withCredentials: true
}

export function loginUser(user){
	return function(dispatch){
		var url = baseurl+'/login'
		axios.post(url,{
			username:user.username,
			password:user.password
		},{headers: headers})
		.then(response => {
			console.log(response)
			dispatch({
				type: LOGIN_USER,
				payload: response.data.msg
			})
		}).catch(err => {
			throw err
		})
	}
	
}

export const logoutUser = () => ({
	type: LOGOUT_USER
})

