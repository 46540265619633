import errorReporter from './error-reporter';
import locationMiddleware from './location';
import mqttLocationMiddleware from './mqtt-location';
import devicePathMiddleware from './device-path';
import reduxThunk from 'redux-thunk';

const middleware = [
  errorReporter,
  locationMiddleware,
  mqttLocationMiddleware,
  devicePathMiddleware,
  reduxThunk
];

export default middleware;
