import { FETCH_DEVICE_PATH, loadDevicePath } from '../actions/location';
import mqtt from 'mqtt';
import { setViewport } from '../actions/map';
//import { startProgress, endProgress } from '../actions/progress';
import createMiddleware from './create-middleware';
import {connect} from 'react-redux';
import resource, { EREQUESTDEDUPLICATION } from '../util/resource';
import { fitMapBounds } from '../vis/coordinate';

// Stateful record of the most recent locations request.
// Used to reject UI updates that are stale (superseded by a more recent request).
let locationLoadID = 0;
/**
 * Start an XHR to fetch location data from the server with the parameters currently in the store.
 */
const fetchDevicePathMiddleware = (store) => {
  const {
    context: { width, height },
    dataSource: { realDevice },
    filters: { accuracyThreshold },
    map: { viewport },
  } = store.getState();
  var mqqturl = "ws://localhost:8000";

  var mqttClient = mqtt.connect(mqqturl,function(err){
    if(err)
      throw err
  });
  
  if(realDevice){
    var topic = "geo/android/5afbb80c769d7d7f7dba570a"
    console.log(topic);
    mqttClient.on("connect",()=>{
      console.log("mqtt connection established...");
      mqttClient.subscribe(topic);
    })
  }

  mqttClient.on("message", function (topic, payload) {
    var json =[];
    var msg = JSON.parse(payload);
    if(msg.type == 'location'){
        var location = {  
        acc : msg.acc,
        tst : msg.tst,
        lat : Number( msg.lat ),
        lng : Number( msg.lon ),  
      }
      json.push(location);
    }
    console.log(json.length);
    console.log(json);
    store.dispatch(loadDevicePath(json));
    //store.dispatch(endProgress());   
  })
  // There can be multiple, concurrent instances of this logic if there are multiple timestamp
  // ranges queried over a short amount of time. Keep a record of the current request ID to ensure
  // that only the most recent request is actually loaded into the global store.
  const request = ++locationLoadID;  // eslint-disable-line no-plusplus

  //store.dispatch(startProgress());
};

const middlewareMapping = {
  [FETCH_DEVICE_PATH]: fetchDevicePathMiddleware,
};

export default createMiddleware(middlewareMapping);
