export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_MQTT_LOCATIONS = 'LOAD_MQTT_LOCATIONS'
export const FETCH_MQTT_LOCATIONS = 'FETCH_MQTT_LOCATIONS';
export const LOAD_DEVICEPATH = 'LOAD_DEVICE_PATH';
export const FETCH_DEVICE_PATH = 'FETCH_DEVICE_PATH';
export const LOAD_DEVICE_PATH = 'LOAD_DEVICE_PATH';

/**
 * Trigger a network request to fetch locations for the currently defined data source parameters in
 * the store.
 *
 * @return {Object} Action for fetching locations.
 */
export const fetchLocations = () => ({
  type: FETCH_LOCATIONS,
});

export const fetchMqttLocations = () => ({
	type:FETCH_MQTT_LOCATIONS,
});

export const fetchDevicePath = () => ({
	type:FETCH_DEVICE_PATH,
})
/**
 * Load an array of locations into the store.
 *
 * @param {Array} locations Array of location objects.
 * @return {Object} Action for loading fetched locations.
 */
export const loadLocations = (locations) => ({
  type: LOAD_LOCATIONS,
  payload: { locations },
});

export const loadMqttLocations = (mqttLocations) => ({
	type: LOAD_MQTT_LOCATIONS,
	payload: {mqttLocations},
});

export const loadDevicePath = (devicePath) => ({
	type: LOAD_DEVICE_PATH,
	payload: {devicePath}
})