import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {connect} from 'react-redux';
import Dashboard from '../../views/Dashboard/Dashboard';
import AdminDashboard from '../../views/Dashboard/AdminDashboard';
import ManagerDashboard from '../../views/Dashboard/ManagerDashboard';


import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import navManager from '../../navManager';
import navAdmin from '../../navUser';
// routes config
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
class DefaultLayout extends Component {

  render() {
    if(this.props.user){
      var navbar = ''
      if(this.props.user.role == 'businessUser')
        navbar = <AppSidebarNav navConfig={navigation} {...this.props} />
      else if(this.props.user.role == 'operationManager')
        navbar = <AppSidebarNav navConfig={navManager} {...this.props} />
      else if (this.props.user.role == 'admin')
        navbar = <AppSidebarNav navConfig={navAdmin} {...this.props} />
    }
    console.log(this.props);
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            {navbar}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <br/>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  if(this.props.user){
                    if(route.path == '/dashboard' && this.props.user.role == 'businessUser')
                      route.component = Dashboard
                    if(route.path == '/dashboard' && this.props.user.role == 'admin')
                      route.component = AdminDashboard
                    if(route.path == '/dashboard' && this.props.user.role == 'operationManager')
                      route.component = ManagerDashboard
                  }
                    return (route.component && this.props.user) ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}

               {(this.props.user)?(this.props.user.role)?<Redirect from="/" to="/deviceInfo"/>:<Redirect from="/" to="/login"/>: <Redirect from="/" to="/login"/>}
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = ({login}) => ({
  user: login.data
})
export default connect(mapStateToProps)(DefaultLayout);

