import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Error from './containers/error';
import withWindowDimensions from './hoc/with-window-dimensions';
import { setWindowDimensions } from './actions/context';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views/Pages';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  constructor(props) {
    super(props);
    this.onUnload = this.onUnload.bind(this); // if you need to bind callback to this
  }


  onUnload(event) { // the method that will be used for both add and remove event
    localStorage.removeKey('persist:root');
    return '';
  }
  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)
  }

  state = { error: null };

  
  render() {
    const { error } = this.state;
    return error ? (
      <Error />
    ) : (
      <div>
        <div
          style={{
            backgroundColor: 'hsl(57, 33%, 100%)',
            height: '100%',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: -1,
          }}
        />

        <HashRouter>
        <Switch>
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route path="/" name="Home" component={DefaultLayout} />
          //{(this.props.user)?<Redirect from="/login" to="/dashboard"/>: <Redirect from="/" to="/login"/>}
        </Switch>
      </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = ({login}) => ({
  user: login.data
})
export default connect(mapStateToProps)(App);




