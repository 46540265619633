/* eslint-disable no-underscore-dangle */

import { compose, createStore, applyMiddleware } from 'redux';
import reducers from '../reducers';
import middleware from '../middleware';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
//import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  blacklist: ['realFeed'],
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)


const isDev = process.env.NODE_ENV !== 'production';
const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

//const store = createStore(persistReducer, );


export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))
export const persistor = persistStore(store);