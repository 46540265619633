export default {
  items: [
    {
      name: 'Overview',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Active Trips',
      url: '/activetrips',
      icon: 'fa fa-automobile'
    },
    {
      title: true,
      name: '',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Drivers',
      url: '/drivers',
      icon: 'icon-puzzle',
    },
    {
      name: 'Route Planning',
      url: '/routePlan',
      icon: 'icon-puzzle',
    },
    {
      name: 'Logs',
      url: '/logs',
      icon: 'icon-puzzle',
    },
    
    {
      title: true,
      name: '',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Vehicles',
      url: '/zoneVehicles',
      icon: 'icon-puzzle',
    },
    {
      name: 'FuelLogs',
      url: '/fuelLogs',
      icon: 'icon-puzzle',
    },
    {
      name: 'BreakDown Logs',
      url: '/breakdownLogs',
      icon: 'icon-puzzle',
    },
    {
      name: 'Accident Logs',
      url: '/accidentLogs',
      icon: 'icon-puzzle',
    },
    {
      name: 'Service Logs',
      url: '#',
      icon: 'icon-puzzle',
      badge: {
        variant: 'info',
        text: 'SOON',
      },
    },
  ],
};
