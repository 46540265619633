import {LOGIN_USER} from '../actions/login';
import {LOGOUT_USER} from '../actions/login';
import createReducer from './create-reducer';

const initialState ={
	data: []
}

const loginUserReducer = (state, action) => ({
	...state,
	data: action.payload
})

const logoutUserReducer = (state, action) => ({
	state: undefined
})

const reducerMapping ={
	[LOGIN_USER] : loginUserReducer,
	[LOGOUT_USER] : logoutUserReducer
}

export const loginReducer = createReducer(reducerMapping, initialState)