export default {
  items: [
    {
      name: 'Overview',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: 'Region Summary',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    
    {
      name: 'By Zone',
      url: '/byZones',
      icon: 'icon-pencil',
    },
    {
      title: true,
      name: '',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Zone Comparision',
      url: '/zoneComparision',
      icon: 'fa fa-automobile'
    },
    {
      name: 'Time Sheet Summary',
      url: '/timeSheet',
      icon: 'icon-puzzle',
    },
    {
      name: 'Tolls & Taxes report',
      url: '/tolls',
      icon: 'icon-puzzle',
    },
    {
      title: true,
      name: '',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Active Vehicles',
      url: '/devices',
      icon: 'icon-puzzle'
    },
    {
      name: 'Manage Alerts',
      url: '/alerts',
      icon: 'icon-puzzle'
    },
    {
      name: 'Create Geofence',
      url: '/geofence',
      icon: 'icon-puzzle'
    },
    {
      name: 'Manage Geofence',
      url: '/manage-geofence',
      icon: 'icon-puzzle'
    },
    {
      name: 'Vehicle Summary',
      url: '/vehicles',
      icon: 'icon-puzzle',
    },
    {
      name: 'DVIR Summary',
      url: '/dvir',// /addDriver
      icon: 'icon-puzzle',
    },
    {
      name: 'Fuel Report',
      url: '/fuel',
      icon: 'icon-puzzle',
    },
    {
      name: 'Breakdown Summary',
      url: '/breakdown',
      icon: 'icon-puzzle',
    },
    {
      name: 'Accident Summary',
      url: '/accident',
      icon: 'icon-puzzle',
    },
    {
      name: 'Service Summary',
      url: '#',
      icon: 'icon-puzzle',
      badge: {
        variant: 'info',
        text: 'SOON',
      },
    }
   
  ]
  }
    