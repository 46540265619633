import WebMercatorViewport from 'viewport-mercator-project';

/**
 * Create a unique array according a particular predicate.
 *
 * @param {Array} arr Input array.
 * @param {Function} predicate Unary function that takes as input the array element and returns a
 *                             string key that uniquely identifies that element. Elements with the
 *                             same key will be deduplicated, favoring the later element in the
 *                             array.
 * @returns {Array} Same input data but with elements deduplicated according to the key generated by
 *                  the specified predicate.
 */
const uniqBy = (arr, predicate) => Object.values(arr
  .map((elem) => ({ key: predicate(elem), elem }))
  .reduce((unique, { key, elem }) => ({
    ...unique,
    [key]: elem,
  }), {}));

/**
 * Determine the appropriate center coordinate and zoom level for the map, given an array of data.
 *
 * @param {Array} data Array of location objects.
 * @param {number} accuracyThreshold Maximum tolerable accuracy threshold in meters.
 * @param {number} width Current width of the viewport.
 * @param {number} height Current height of the viewport.
 * @returns {Object} An object containing center and zoom properties describing how best to fit the
 *                   map bounds to the data..
 */
export const fitMapBounds = (data, accuracyThreshold, width, height) => {
  console.log(data);
  console.log(width);
  // Fit only location points that are unique keyed by their latitude and longitude.
  const eligibleData = data.filter(( {accuracy} ) => accuracy < accuracyThreshold);
  const dedupedData = uniqBy(eligibleData, ({ latitude, longitude }) => `${latitude}-${longitude}`);
  console.log(eligibleData);
  console.log(dedupedData);
  // Use a sane default zoom level if there's only a single eligible point.
  if (dedupedData.length === 1) {
    const [{ latitude, longitude }] = dedupedData;
    return { latitude, longitude, zoom: 15 };
  }

  const { minLongitude, minLatitude, maxLongitude, maxLatitude } = dedupedData
    .reduce((acc, val) => ({
      minLongitude: Math.min(acc.minLongitude, val.longitude),
      maxLongitude: Math.max(acc.maxLongitude, val.longitude),
      minLatitude: Math.min(acc.minLatitude, val.latitude),
      maxLatitude: Math.max(acc.maxLatitude, val.latitude),
    }), {
      minLongitude: Infinity,
      maxLongitude: -Infinity,
      minLatitude: Infinity,
      maxLatitude: -Infinity,
    });
  const bounds = [[minLongitude, minLatitude], [maxLongitude, maxLatitude]];
  console.log(minLongitude);
  console.log(bounds);

  return new WebMercatorViewport({ width, height }).fitBounds(bounds, { padding: 40 });
};

export default undefined;
