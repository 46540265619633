import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

const ProvisionDevice = Loadable({
  loader: () => import('./views/ProvisionDevice'),
  loading: Loading
})

const TrackAndTrace = Loadable({
  loader: () => import('./views/TrackAndTrace/TrackAndTrace'),
  loading: Loading
})

const GoogleMaps = Loadable({
  loader: () => import('./views/TrackAndTrace/googleMaps'),
  loading: Loading
})

const RealTimeMaps = Loadable({
  loader: () => import('./views/TrackAndTrace/realTimeMaps'),
  loading: Loading
})

const DevicePath = Loadable({
  loader: () => import('./views/TrackAndTrace/deviceRealPath'),
  loading: Loading
})

const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

const ByZones = Loadable({
  loader: () => import('./views/BusinessUser/byzone'),
  loading: Loading,
});

const AdminZoneComparision = Loadable({
  loader: () => import('./views/BusinessUser/zoneComparision'),
  loading: Loading,
});

const AdminTimeSheets = Loadable({
  loader: () => import('./views/BusinessUser/timeSheet'),
  loading: Loading,
})

const AdminTolls = Loadable({
  loader: () => import('./views/BusinessUser/tolls-taxes'),
  loading: Loading,
});

const AdminVehicles = Loadable({
  loader: () => import('./views/BusinessUser/vehicles'),
  loading: Loading,
});

const AdminFuel = Loadable({
  loader: () => import('./views/BusinessUser/fuel'),
  loading: Loading,
});

const AdminBreakdown = Loadable({
  loader: () => import('./views/BusinessUser/breakdown'),
  loading: Loading,
});

const AdminAccident = Loadable({
  loader: () => import('./views/BusinessUser/accident'),
  loading: Loading,
});

const AdminDVIR = Loadable({
  loader: () => import('./views/BusinessUser/dvir'),
  loading: Loading,
});

const Devices = Loadable({
  loader: () => import('./views/BusinessUser/devices'),
  loading: Loading,
});

const ManageAlerts = Loadable({
  loader: () => import('./views/BusinessUser/manage-alerts'),
  loading: Loading,
});

const Geofence = Loadable({
  loader: () => import('./views/BusinessUser/geofence'),
  loading: Loading,
});

const ManageGeofence = Loadable({
  loader: () => import('./views/BusinessUser/manage-geofences'),
  loading: Loading
});

const AddDriver = Loadable({
  loader: () => import('./views/AddControls/addDriver'),
  loading: Loading,
});

const AddVehicle = Loadable({
  loader: () => import('./views/AddControls/addVehicle'),
  loading: Loading,
});

const AddDevice = Loadable({
  loader: () => import('./views/AddControls/addDevice'),
  loading: Loading,
});

const ActiveTrips = Loadable ({
  loader: () => import('./views/OperationManager/ActiveTrips'),
  loading: Loading,
});

const ManagerDrivers = Loadable ({
  loader: () => import('./views/OperationManager/drivers'),
  loading: Loading,
});

const CurrentTrip = Loadable({
  loader: () => import('./views/OperationManager/currentTrip'),
  loading:Loading,
});

const DriverTrips = Loadable({
  loader: () => import('./views/OperationManager/driver'),
  loading: Loading,
});

const TripHistory = Loadable ({
  loader: () => import('./views/OperationManager/trip-history'),
  loading: Loading,
});

const ManagerRoutes = Loadable ({
  loader: () => import('./views/OperationManager/routePlanning'),
  loading: Loading,
});

const ManagerLogs = Loadable ({
  loader: () => import('./views/OperationManager/logs'),
  loading: Loading,
});

const ManagerVehicles = Loadable ({
  loader: () => import('./views/OperationManager/vehicles'),
  loading: Loading,
});

const ManagerFuel = Loadable ({
  loader: () => import('./views/OperationManager/fuel'),
  loading: Loading,
});

const ManagerBreakdown = Loadable ({
  loader: () => import('./views/OperationManager/breakdown'),
  loading: Loading,
});

const ManagerAccident = Loadable ({
  loader: () => import('./views/OperationManager/accident'),
  loading: Loading,
});

const DeviceInfo = Loadable({
  loader: () => import('./views/Devices/DeviceInfo'),
  loading: Loading
})

const DeviceStatus = Loadable({
  loader: () => import('./views/Devices/DeviceStatus'),
  loading: Loading
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', exact: true, name: 'Dashboard' },
  { path: '/byZones', exact: true, name: 'By Zones', component: ByZones },
  { path: '/byZones/:zone', name: 'By Zones', exact: true, component: ByZones },
  { path: '/vehicles', name:'Vehicles', component:AdminVehicles },
  { path: '/timeSheet', name:'Time Sheet Summary', component:AdminTimeSheets },
  { path: '/tolls', name:'Tolls', component:AdminTolls },
  { path: '/zoneComparision', name:'Zone Comparision', component:AdminZoneComparision },
  { path: '/fuel', name:'Fuel Report', component:AdminFuel },
  { path: '/breakdown', name:'Breakdown Summary', component:AdminBreakdown },
  { path: '/accident', name:'Accident Summary', component:AdminAccident },
  { path: '/dvir', name:'DVIR', component:AdminDVIR },
  { path: '/zoneComparision', name:'Zone Comparision', component:AdminZoneComparision },
  { path: '/devices', name:'Active Devices', component:Devices },
  { path: '/geofence', name:'Geofence', component:Geofence },
  { path: '/manage-geofence', name:'Manage Geofence', component:ManageGeofence },
  { path: '/alerts', name:'Alerts', component:ManageAlerts },
  //{ path: '/businessAdm', excat: true, name: 'Home', component: UserLayout },
  //{ path: '/businessAdmin/dashboard', name:'Admin', component: AdminUserDashboard },
  { path: '/addDriver', exact: true, name: 'By Operation Team', component: AddDriver },
  { path: '/addVehicle', exact: true, name: 'By Operation Team', component: AddVehicle },
  { path: '/addDevice', exact: true, name: 'By Operation Team', component: AddDevice },
  //{ path: '/manage', exact: true, name: 'By Operation Manager', component: ManagerLayout },
  //{ path: '/manager/dashboard', excat: true, name:'By Operation Mangaer', component: ManagerDashboard },
  { path: '/activetrips', exact: true, name:'By Operation Mangaer', component: ActiveTrips },
  { path: '/drivers', exact: true, name: 'By Operation Mangaer', component: ManagerDrivers },
  { path: '/routePlan', exact: true, name: 'By OperationManager', component: ManagerRoutes },
  { path: '/logs', exact: true, name: 'By OperationManager', component: ManagerLogs },
  { path: '/fuelLogs', exact: true, name: 'By OperationManager', component: ManagerFuel },
  { path: '/zoneVehicles', exact: true, name: 'By OperationManager', component: ManagerVehicles },
  { path: '/breakdownLogs', exact: true, name: 'By OperationManager', component: ManagerBreakdown },
  { path: '/accidentLogs', exact: true, name: 'By OperationManager', component: ManagerAccident },
  { path: '/deviceinfo', exact:true, name: 'Device Info', component: DeviceInfo},
  { path: '/devicestatus', exact:true , name: 'Device status', component: DeviceStatus},
  { path: '/deviceRegistration', name: 'Forms', component: Forms },
  { path: '/provisionDevice', name: 'ProvisionDevice', component: ProvisionDevice },
  { path: '/track-and-trace', name:'TrackAndTrace', component: TrackAndTrace},
  { path: '/track/googlemaps', name: 'GoogleMaps', component: GoogleMaps },
  { path: '/track/realTimeMaps', name: 'RealTimeMaps', component: RealTimeMaps },
  { path: '/track/realDevicePath', name: 'DevicePath', component: DevicePath },
  { path: '/base/devices', name: 'Devices', component: Tables },
  { path: '/trip/:tripId', name: 'By Operation Manager', component: TripHistory},
  { path: '/driver/:driver', name: 'By Operation Manager', component: DriverTrips },
  { path: '/:tripId', name: 'By Operation Manager', component: CurrentTrip },
 
];

export default routes;
