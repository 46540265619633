import { combineReducers } from 'redux';
/*import contextReducer from './context';
import dataSourceReducer from './data-source';
import filtersReducer from './filters';
import {locationReducer,pathReducer} from './location';
import mapReducer from './map';
import optionsReducer from './options';
import progressReducer from './progress';*/
import {loginReducer} from './login';
import {feedReducer} from './progress';

const reducer = combineReducers({
  login: loginReducer,
  realFeed: feedReducer
});

export default reducer;
