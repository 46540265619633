import React, { Component } from 'react';
import Dashboard from './Dashboard';

class AdminDashboard extends Component {

  render() {
    return (
      <Dashboard/>
    );
  }
}

export default AdminDashboard;
