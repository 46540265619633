import { START_FEED, END_FEED } from '../actions/progress';
import createReducer from './create-reducer';

const initialState = {
  realFeed: false,
};

const startFeedReducer = (state) => ({
  ...state,
  realFeed: true,
});

const endFeedReducer = (state) => ({
  ...state,
  realFeed: false,
});

const reducerMapping = {
  [START_FEED]: startFeedReducer,
  [END_FEED]: endFeedReducer,
};

export const feedReducer = createReducer(reducerMapping, initialState)
