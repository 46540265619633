/*global google*/
import React,{Component} from 'react';
import { compose, withProps, lifecycle } from "recompose"
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import mqtt from 'mqtt';
import {connect} from 'react-redux';
import {realFeedOn, realFeedOff} from '../../actions/progress';
import {Button, Row, Col} from 'reactstrap';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import axios from 'axios';
import baseurl from '../../util/baseurl';

const urlForHeatmapRoutes = baseurl+'/heatmap'

class Devices extends Component{
  constructor(props){
    super(props)
    this.state={
      driverPosition: {lat:17.4289138, lng:78.4281344},
      showHeatMap: false,
      selectedMarker:'',
      mapRawData: [],
      mapTypeButtonName: 'HeatMap'
    }
    this.showHeatMap = this.showHeatMap.bind(this)
    this.showActiveDevices = this.showActiveDevices.bind(this)
  }
  
  handleClick = (marker, event) => {
    this.setState({ selectedMarker: marker })
  }

  showHeatMap(){
    axios.get(urlForHeatmapRoutes)
    .then(response=>{
      this.setState({
        showHeatMap: true,
        mapRawData: response.data.data,
        mapTypeButtonName: 'Active Devices'
      })
    })
    .catch(err => {
      throw err
    })
  }
  showActiveDevices(){
    this.setState({
      showHeatMap: false,
      mapTypeButtonName: 'HeatMap'
    })
  }
  render(){
    
  	const MyMapComponent = compose(
      withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ width: `100%` }} />,
        mapElement: <div style={{height: `500px`, width: `100%` }}  />,
      }),
      withGoogleMap,
      lifecycle({
        componentWillMount(){
          var data = [
            {id: "5bae137db41aae003f92e9d7",vehicleId:'TS 09 A 4390',accuracy:10, time: 1538395294, lat: 17.4289138, lng: 78.4281344},
            {id:'123',vehicleId:'TS 09 A 2457',accuracy:30.4,lat:17.437699,lng:78.3891248,time:1536400912543},
            {id:'5afbb80c769d7d7f7dba570a',vehicleId:'TS 07 J 9867',accuracy:26.2,lat:17.4289133,lng:78.4281321,time:1538149537457},
            {id:'456',vehicleId:'TS 07 J 6783',accuracy:12.9,lat:17.4518362,lng:78.3922816,time:1536402221000},
            {id:'789',vehicleId:'TS 07 J 7530',accuracy:10.0,lat:17.4698294,lng:78.3909692,time:1536403166000},
            {id:'234',vehicleId:'TS 07 J 8370',accuracy:96.0,lat:17.4697812,lng:78.4246243,time:1536403672000},
           ]
          this.setState({
            sampleFilterPolyline:data
          })
        },
        componentDidMount() { 
          var mqtturl = "wss://tt.thingsphere.com/wss/";
          //var mqtturl = "ws://localhost:8000"
          var data = [
            {id: '5bae137db41aae003f92e9d7',vehicleId:'TS 09 A 4390',accuracy:12, time: 1538395294, lat: 17.4289138, lng: 78.4281344},
            {id:'123',vehicleId:'TS 09 A 2457',accuracy:30.4,lat:17.437699,lng:78.3891248,time:1536400912543},
            {id:'5afbb80c769d7d7f7dba570a',vehicleId:'TS 07 J 9867',accuracy:26.2,lat:17.4289133,lng:78.4281321,time:1538149537457},
            {id:'456',vehicleId:'TS 07 J 6783',accuracy:12.9,lat:17.4518362,lng:78.3922816,time:1536402221000},
            {id:'789',vehicleId:'TS 07 J 7530',accuracy:10.0,lat:17.4698294,lng:78.3909692,time:1536403166000},
            {id:'234',vehicleId:'TS 07 J 8370',accuracy:96.0,lat:17.4697812,lng:78.4246243,time:1536403672000},
           ]
          var filteredData = []
          for (var i = 0; i < this.props.mapRawData.length; i++) {
            const point = this.props.mapRawData[i];
            const htmapPoint = {
              location: new google.maps.LatLng(
                point.latitude,
                point.longitude
              )
            };
            filteredData.push(htmapPoint);
          }
          this.setState({mapData: filteredData});
          const mqttClient = this.mqttClient =mqtt.connect(mqtturl,function(err){
            if(err)
              throw err
          }); 

          if(!this.props.showHeatMap){
            mqttClient.on("connect",()=>{
              console.log("mqtt connection established...");
              mqttClient.subscribe("geo/android/#");
              this.props.realFeedOn()
            })
          }
          mqttClient.on("message", function (topic, payload) {
            var msg = JSON.parse(payload);
            var prevDevices = data.slice();
            var devices = prevDevices.slice();
            if(msg.type == 'location'){
              prevDevices.forEach((device,index)=>{
                if(device.id == msg.id){
                  device.lat = msg.lat
                  device.lng = msg.lon
                }
              })
            }
            this.setState({
              sampleFilterPolyline:devices,
            })
          }.bind(this))
        },
        componentWillUnmount(){
          console.log('mqtt connection closed')
          this.mqttClient.end()
          this.props.realFeedOff();
        }
      })
    )((props) =>
    <GoogleMap
      defaultZoom={13}
      defaultCenter={props.currentPosition}
    >
      {(props.showHeatMap)?'':props.sampleFilterPolyline.map((marker, index) => {
      const onClick = props.onClick.bind(this, marker)
        return (
          <Marker
            key={index}
            onClick = {onClick}
            position={{lat:marker.lat, lng:marker.lng}}
            options={{ icon: {url: 'assets/img/avatars/pulse.gif', scaledSize: { width: 32, height: 32 }} }}
          >
            <InfoWindow>
                <div>
                  {marker.vehicleId}
                </div>
              </InfoWindow>
          </Marker>
        )
      })}
      {(props.showHeatMap)?<HeatmapLayer data={props.mapData} options={{opacity: 1,
      dissipating: true, maxIntensity: 10}}/>:''}
    </GoogleMap>
  );
  return(
    <div>
      {(this.props.match)?<Row>
        <Col md="2">
          {(!this.state.showHeatMap)?<Button block color="info" onClick={this.showHeatMap}>{this.state.mapTypeButtonName}</Button>:''}
          {this.state.showHeatMap?<Button block color="info" onClick={this.showActiveDevices}>{this.state.mapTypeButtonName}</Button>:''}
        </Col>
      </Row>:''}
      
      <br/>
      <MyMapComponent onClick={this.handleClick} currentPosition = {this.state.driverPosition} 
      selectedMarker={this.state.selectedMarker} realFeedOn={this.props.realFeedOn} 
      realFeedOff={this.props.realFeedOff} showHeatMap={this.state.showHeatMap} mapRawData={this.state.mapRawData}/>
    </div>
    )
  }
}

export default connect(null,{realFeedOn, realFeedOff})(Devices);



















