import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup,
Form, InputGroupAddon, InputGroupText, Row, Modal, ModalHeader, ModalBody, FormGroup,
Label, ModalFooter } from 'reactstrap';
import logo from '../../../assets/img/brand/thingsphere-black.png'
import axios from 'axios';
import {loginUser} from '../../../actions/login';
import {connect} from 'react-redux';
import baseurl from '../../../util/baseurl'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state={
      username: '',
      password: '',
      message: '',
      name: '',
      email: '',
      phone: '',
      role: '',
      zone:'',
      loginUser:'',
      loginPassword:'',
      loginRole:'',
      msg:''
    }
    this.handleRegisterChange=this.handleRegisterChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleRegisterSubmit=this.handleRegisterSubmit.bind(this);
    this.toggle=this.toggle.bind(this);
    this.loginHandler=this.loginHandler.bind(this);
  }

  loginHandler(e) {
    e.preventDefault()
    var user ={
      username: this.state.loginUser,
      password: this.state.loginPassword
    }
    this.props.loginUser(user)
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.user){
      if(nextProps.user.msg=='Invalid username or password.'||nextProps.user.msg=='Username not found.'){
        this.setState({
          msg: nextProps.user.msg
        })
      }else{
        this.props.history.push('/deviceInfo')
      } 
    }
  }
  handleChange(e){
    var name = e.target.name;
    this.setState({
      [name] : e.target.value
    })
  }

  handleRegisterChange(event){
    const target = event.target;
    const value = target.value;
    const name = target.name ;
    this.setState({
      [name]: value
    });
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }
  handleRegisterSubmit(e){
    var urlToSignup = baseurl+'/signup'
    axios.post(urlToSignup,{
      name:this.state.name,
      password:this.state.password,
      username:this.state.username,
      email:this.state.email,
      phone:this.state.phone,
      role:this.state.role,
      zone:this.state.zone
    }).then(response =>{
      console.log(response.data)
      this.setState({
        modal: !this.state.modal,
        message: response.data.msg
      }) 
    }).catch(err =>{
      throw err
    })  
  }
  render() {
    var partial =''
    if(this.state.role == 'operationManager'){ 
      partial = <div>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="zone">Zone</Label>
          </Col>
          <Col xs="12" md="9">
            <Input type="select" id="zone" name="zone" placeholder="Zone" 
            value={this.state.zone} onChange={this.handleRegisterChange}>
              <option value="" disabled>Select Zone</option>
              <option value="zone-1">Zone-1</option>
              <option value="zone-2">Zone-2</option>
              <option value="zone-3">Zone-3</option>
              <option value="zone-4">Zone-4</option>
            </Input>
          </Col>
        </FormGroup>
      </div>
    }
    else{
      partial = <div></div>
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <div className="device">
              <span className="device-status badge-success"></span>&nbsp;
            </div>
            <p><b>All Real Time Feeds online</b></p>
          </Row>
          <Row className="justify-content-center">
            <img src={logo} width="250" height="35" alt="thingsphere"/>
          </Row>
          <br/>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.loginHandler} encType="multipart/form-data">
                      <h2 className="pb-4 text-muted">Machine Dashboard</h2>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Username" id="loginUser" name="loginUser" value={this.state.loginUser}
                        onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" id="loginPassword" name="loginPassword" value={this.state.loginPassword}
                        onChange={this.handleChange} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button onSubmit={this.loginHandler} color="success" className="px-4">Login</Button>
                        </Col>  
                      </Row>
                    </Form>
                    <p>{this.state.message}</p>
                    <p className="alert-warning">{this.state.msg}</p>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <br/><br/>
                      <Button disabled color="info" className="px-0" onClick={this.toggle}>Register User</Button>
                      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Register User</ModalHeader>
                        <ModalBody>
                          <Card>
                            <CardBody>
                              <Form onSubmit={this.handleRegisterSubmit} encType="multipart/form-data" className="form-horizontal">
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="name">Name</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="text" id="name" name="name" placeholder="Name" 
                                    value={this.state.name} onChange={this.handleRegisterChange}/>
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="password">Password</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="password" id="password" name="password" placeholder="Password" 
                                    value={this.state.password} onChange={this.handleRegisterChange}/>
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="username">Username</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="text" id="username" name="username" placeholder="Username" 
                                    value={this.state.username} onChange={this.handleRegisterChange}/>
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="email">Email</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="email" id="email" name="email" placeholder="Email" 
                                    value={this.state.email} onChange={this.handleRegisterChange}/>
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="phone">Phone</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="text" id="phone" name="phone" placeholder="Phone" 
                                    value={this.state.phone} onChange={this.handleRegisterChange}/>
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col md="3">
                                    <Label htmlFor="role">Role</Label>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <Input type="select" id="role" name="role" placeholder="Role" 
                                    value={this.state.role} onChange={this.handleRegisterChange}>
                                      <option value='' disabled>Select Role</option>
                                      <option value="businessUser">Business User</option>
                                      <option value="admin">Admin</option>
                                      <option value="operationManager">Operation Manager</option>
                                    </Input>
                                  </Col>
                                </FormGroup>
                                {partial}
                              </Form>
                            </CardBody>
                          </Card>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="success" onClick={this.handleRegisterSubmit}>Register User</Button>{' '}
                          <Button color="danger" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md="1">
            </Col>
            <Col md="10">
              <p className='register-message'>This is a demo server and sign-ups are disabled. Kindly contact us at: <a href="mailto:info@thingsphere.com">info@thingsphere.com</a> for a live demo of this platform - including real time vehicle and tracking data, vehicle management, route planning and a host of other features.</p> 
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({login}) => ({
  user: login.data
})
export default connect(mapStateToProps,{loginUser})(Login);