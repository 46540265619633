import { FETCH_MQTT_LOCATIONS, loadMqttLocations } from '../actions/location';
import mqtt from 'mqtt';
import { setViewport } from '../actions/map';
//import { startProgress, endProgress } from '../actions/progress';
import createMiddleware from './create-middleware';
import {connect} from 'react-redux';
import resource, { EREQUESTDEDUPLICATION } from '../util/resource';
import { fitMapBounds } from '../vis/coordinate';

// Stateful record of the most recent locations request.
// Used to reject UI updates that are stale (superseded by a more recent request).
let locationLoadID = 0;
/**
 * Start an XHR to fetch location data from the server with the parameters currently in the store.
 */
const fetchMqttLocationsMiddleware = (store) => {
  const {
    context: { width, height },
    dataSource: { realDevice },
    filters: { accuracyThreshold },
    map: { viewport },
  } = store.getState();

  var mqqturl = "ws://localhost:8000";

  var mqttClient = mqtt.connect(mqqturl,function(err){
    if(err)
      throw err
  });
  
  mqttClient.on("connect",()=>{
    console.log("mqtt connection established...");
    mqttClient.subscribe("geo/android/#");
  })

  mqttClient.on("message", function (topic, payload) {
    var json =[];
    console.log(JSON.parse(payload));
    var msg = JSON.parse(payload);
    if(msg.type == 'location'){
        var location = {
        id:msg.id,  
        accuracy : msg.acc,
        timestamp : msg.tst,
        latitude : msg.lat,
        longitude : msg.lon,  
      }
      json.push(location);
    }
    console.log(json.length);
    
    store.dispatch(loadMqttLocations(json));
    //store.dispatch(endProgress()); 
    mqttClient.end();  
  })
  // There can be multiple, concurrent instances of this logic if there are multiple timestamp
  // ranges queried over a short amount of time. Keep a record of the current request ID to ensure
  // that only the most recent request is actually loaded into the global store.
  const request = ++locationLoadID;  // eslint-disable-line no-plusplus

  //store.dispatch(startProgress());
};

const middlewareMapping = {
  [FETCH_MQTT_LOCATIONS]: fetchMqttLocationsMiddleware,
};

export default createMiddleware(middlewareMapping);
